import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
    {
        path: "/",
        name: "Home",
        redirect: '/zhIndex',
    },
    {
        path: '/zhIndex',
        name: 'zhIndex',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/zh.vue')
    },
    {
        path: '/enIndex',
        name: 'enIndex',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/en.vue')
    }
]

const router = new VueRouter({
    mode: 'history', // 使用history模式
    routes
})

export default router
